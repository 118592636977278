<template>
  <div class="container" style="max-width: 1600px">
    <div class="row">
      <div class="col-12">
        <h1>8k-копирайтинг (прототип)</h1>
      </div>
    </div>
    <div>
      <b-card no-body>
        <b-tabs card v-model="tabIndex">
          <b-tab>
            <template #title>
              Шаг 1 <br />
              <small>Генерация текста</small>
            </template>

            <b-card-text>
              <h5>Сгенерируйте текст по тезисам</h5>
              <div>
                <div class="row" style="justify-content: center">
                  <div class="col-12">
                    Добавьте тезисы:
                    <div id="rawAbstracts" contenteditable="true" class="main-div-abstracts color-hover" data-text="...">
                    </div>
                  </div>
                </div>
                <div class="row mt-2" style="justify-content: center">
                  <div class="col-4">
                    <div class="form-group">
                      <label> Выберите стиль: </label>
                      <select class="form-control custom-select rounded" v-model="styleSelected">
                        <option v-bind:value="x" v-for="x in styleOptions">
                          {{ x.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label> Выберите модель: </label>
                      <select class="form-control custom-select rounded" v-model="modelSelected">
                        <option v-bind:value="x" v-for="x in modelOptions">
                          {{ x.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row" style="justify-content: center">
                  <a class="btn btn-sm btn-outline-primary" v-on:click.prevent="showExtraOptions = ~showExtraOptions">
                    <span v-if="showExtraOptions">
                      Скрыть дополнительные параметры генерации
                    </span>
                    <span v-else>
                      Показать дополнительные параметры генерации
                    </span>
                  </a>
                </div>
                <div v-if="showExtraOptions" class="mt-2 mb-0">
                  <div class="row">
                    <div class="col-2">
                      <div class="form-group">
                        <label> top_k </label>
                        <input class="form-control" v-model="topKSelected" />
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label> top_p </label>
                        <input class="form-control" v-model="topPSelected" />
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label> Минимальное число токенов </label>
                        <input class="form-control" v-model="minLengthSelected" />
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label> Максимальное число токенов </label>
                        <input class="form-control" v-model="maxLengthSelected" />
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label> temperature </label>
                        <input class="form-control" v-model="temperatureSelected" />
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label> repetition penalty </label>
                        <input class="form-control" v-model="repetitionPenaltySelected" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2" style="justify-content: center">
                  <div class="col-12">
                    <div class="col-md-12 text-center m-2">
                      <b-button @click="generateText()" class="vs-con-loading__container"
                        id="div-with-loading-generation">Сгенерировать</b-button>
                    </div>
                  </div>
                </div>
                <div class="row" style="justify-content: center">
                  <div class="col-12">
                    Результат генерации:
                    <div id="generationResult" contenteditable="false" class="main-div color-hover">
                      ...
                    </div>
                  </div>
                </div>
                <div class="col text-center mt-4">
                  <b-button @click="tabIndex++">Сохранить результат и перейти к Шагу 2</b-button>
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab>
            <template #title>
              Шаг 2 <br />
              <small>Редактирование</small>
            </template>
            <b-card-text>
              <h5>Редактирование текста</h5>
              <div class="row vs-con-loading__container" id="div-with-loading" style="justify-content: center">
                <div class="col-7">
                  <div class="row">
                    <div class="col">
                      Форма редактирования текста:
                      <div id="textEdit" contenteditable="true" v-on:input="" class="main-div-edit color-hover"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-center m-4">
                      <b-button @click="downloadInnerHtml()">Сохранить результат в файл</b-button>
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  Подсказки:
                  <b-card no-body>
                    <b-tabs card v-model="subTabIndex">
                      <b-tab title="Соотвествие стилю" active>
                        <b-card-text>
                          <div class="row" style="justify-content: center">
                            <div class="col-12">
                              <div contenteditable="false" class="main-div color-hover">
                                <b-button @click="checkTOV()">Проверить текст на соответствие TOV</b-button>
                                <br />
                                <br />
                                Степень соответствия стилю:
                                <strong>{{ this.tov_score }}</strong>%
                                <br />
                                <br />
                                Легенда:<br />
                                <span style="background-color: red">красный</span>
                                обозначает слова или части слов которые сильно
                                влияют на НЕ соответствие стилю.<br />
                                <span style="background-color: green">зеленый</span>
                                обозначает слова или части слов которые сильно
                                вляяют на соответствие стилю.<br />
                                Чем слабее цвет тем меньше влияние слова. Очень
                                слабый
                                <span style="background-color: rgb(255, 0, 0, 0.1)">красный</span>
                                или
                                <span style="background-color: rgb(0, 255, 0, 0.05)">зеленый</span>
                                (или отсутствие цвета) говорит о том что слово
                                нейтрально.
                                <br />
                                <br />
                                Слова, негативно влияющие на соответствие стилю,
                                рекомендуется заменить. Каждое
                                <span style="background-color: red">негативное</span>
                                слово, для которого автоматически подобраны
                                замены, подчеркивается
                                <span class="custom-tooltip" contenteditable="false">пунктиром</span>. Список замен
                                доступен при наведении на слово.
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="Грамматика">
                        <b-card-text>
                          <div class="row" style="justify-content: center">
                            <div class="col-12">
                              <div contenteditable="false" class="main-div color-hover">
                                <b-button @click="checkPunctuation()">Проверить правописание в тексте</b-button>
                                <br />
                                <br />
                                Всего найдено
                                <strong> {{ punctuationErrorsCount }} </strong>
                                ошибок.
                                <br />
                                <br />
                                Модель проверяет орфографию и пунктуацию.
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="Замены">
                        <b-card-text>
                          <div class="row" style="justify-content: center">
                            <div class="col-12">
                              <div contenteditable="false" class="main-div color-hover">
                                <b-button @click="checkParaphrases()">Предложить замены</b-button>
                                <br />
                                <br />
                                Для каждого предложения в тексте модель
                                предлагает альтернативную замену.
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import { HelpCircleIcon } from "vue-feather-icons";

export default {
  components: {
    HelpCircleIcon,
  },
  name: "8k-copywriting",
  data() {
    return {
      true_hostname: "https://ai-marketing.space/api",
      modelOptions: [
        {
          id: 1,
          value: "wizard-vicuna-7b-lora04",
          default_params: {
            min_length: "300",
            max_length: "500",
            top_k: "40",
            top_p: "0.95",
            temperature: "1.0",
            repetition_penalty: "1.0",
          },
        },
        {
          id: 2,
          value: "llama2-saiga",
          default_params: {
            min_length: "300",
            max_length: "500",
            top_k: "40",
            top_p: "0.95",
            temperature: "1.0",
            repetition_penalty: "1.0",
          },
        },
      ],
      modelSelected: {
        id: 2,
        value: "llama2-saiga",
        default_params: {
          min_length: "300",
          max_length: "500",
          top_k: "40",
          top_p: "0.95",
          temperature: "1.0",
          repetition_penalty: "1.0",
        },
      },
      styleOptions: [{ id: 1, value: "Сберпресс" }],
      styleSelected: { id: 1, value: "Сберпресс" },
      tabIndex: 0,
      subTabIndex: 1,
      generationResult: "",
      textForEdit: "",
      minLengthSelected: "300",
      maxLengthSelected: "500",
      topKSelected: "40",
      topPSelected: "0.95",
      temperatureSelected: "1.0",
      repetitionPenaltySelected: "1.0",
      generatorQuery: {
        model: "",
        style: "",
        title: "_",
        abstracts: "",
        min_length: "",
        max_length: "",
        top_k: "",
        top_p: "",
        temperature: "",
        repetition_penalty: "",
      },
      checkerQuery: {
        text: "",
      },
      tovQuery: {
        text: "",
      },
      paraphraseseQuery: {
        text: "",
      },
      tov_score: 0,
      punctuationErrorsCount: 0,
      result_raw: [],
      showExtraOptions: false,
    };
  },
  computed: {},
  watch: {
    modelSelected(val) {
      this.modelSelected = val;
      this.minLengthSelected = this.modelSelected.default_params.min_length;
      this.maxLengthSelected = this.modelSelected.default_params.max_length;
      this.topPSelected = this.modelSelected.default_params.top_p;
      this.topKSelected = this.modelSelected.default_params.top_k;
      this.temperatureSelected = this.modelSelected.default_params.temperature;
      this.repetitionPenaltySelected = this.modelSelected.default_params.repetition_penalty;
    },
    styleSelected(val) {
      this.styleSelected = val;
    },
    minLengthSelected(val) {
      this.minLengthSelected = val;
    },
    maxLengthSelected(val) {
      this.maxLengthSelected = val;
    },
    topPSelected(val) {
      this.topPSelected = val;
    },
    topKSelected(val) {
      this.topKSelected = val;
    },
    temperatureSelected(val) {
      this.temperatureSelected = val;
    },
    repetitionPenaltySelected(val) {
      this.repetitionPenaltySelected = val;
    },
    generationResult(val) {
      this.textForEdit = val;
      let content = document.getElementById("textEdit");
      content.innerText = this.textForEdit;
    },
  },

  methods: {
    prepareGeneratorQuery() {
      this.generatorQuery.model = this.modelSelected.value;
      this.generatorQuery.style = this.styleSelected.value;
      this.generatorQuery.title = "";
      this.generatorQuery.abstracts = JSON.stringify(
        document.getElementById("rawAbstracts").innerText.split("\n")
      );
      this.generatorQuery.min_length = this.minLengthSelected;
      this.generatorQuery.max_length = this.maxLengthSelected;
      this.generatorQuery.top_k = this.topKSelected;
      this.generatorQuery.top_p = this.topPSelected;
      this.generatorQuery.temperature = this.temperatureSelected;
      this.generatorQuery.repetition_penalty = this.repetitionPenaltySelected;
    },
    async generateText() {
      console.log("start generateText");

      this.$vs.loading({
        container: "#div-with-loading-generation",
        scale: 0.6,
        opacity: 0.1,
      });

      this.prepareGeneratorQuery();
      console.log(this.generatorQuery);

      let params = Object.entries(this.generatorQuery)
        .map(([k, v]) => k + "=" + v)
        .join("&");
      console.log(params);

      const evtSource = new EventSource(
        "https://ai-marketing.space/api/8k/eight_k_generation_process_stream?" +
        params
      );

      let content = document.getElementById("generationResult");
      content.innerText = "";

      this.stopGeneration = false;
      let that = this;

      function updateEvent(event) {
        console.log(event);
        let message = event.data;
        console.log(" message: " + message + " ");
        if (!that.stopGeneration) {
          let content = document.getElementById("generationResult");
          content.innerText = content.innerText + message + "\n\n";
        }
      }
      function closeEvent(event) {
        console.log("end");
        that.$vs.loading.close(
          "#div-with-loading-generation > .con-vs-loading"
        );
        that.stopGeneration = true;
        event.currentTarget.close();
      }

      evtSource.addEventListener(
        "update_" + this.generatorQuery.model,
        updateEvent
      );
      evtSource.addEventListener(
        "end_" + this.generatorQuery.model,
        closeEvent
      );
    },
    downloadInnerHtml() {
      let elHtml = document.getElementById("textEdit").innerText;
      let link = document.createElement("a");
      link.setAttribute("download", "result.doc");
      link.setAttribute(
        "href",
        "data:" + "text/doc" + ";charset=utf-8," + encodeURIComponent(elHtml)
      );
      link.click();
    },
    replaceText(i) {
      console.log("replaceText", i);
      this.raw_result[i].original_text = this.raw_result[i].candidate_text;
      this.raw_result[i].operation = "equal";
      this.rebuildTextForEdit();
    },
    rebuildTextForEdit() {
      this.punctuationErrorsCount = 0;
      let result = "";
      this.textForEdit = "";
      for (let i = 0; i < this.raw_result.length; i++) {
        if (this.raw_result[i]["operation"] !== "equal") {
          result +=
            `
                    <div class="custom-tooltip" contenteditable="false">` +
            this.raw_result[i].original_text +
            `<span class="custom-tooltiptext custom-tooltip-top">
                          <a
                            id="` +
            i +
            `"
                            class="btn btn-outline-primary btn-sm mr-2 mt-2 discard-replace-button"
                            >Заменить на "` +
            this.raw_result[i].candidate_text +
            `"</a
                          >
                      </span>
                    </div>`;
          this.punctuationErrorsCount += 1;
        } else {
          result += this.raw_result[i].original_text;
        }
        this.textForEdit += this.raw_result[i].original_text;
      }
      document.getElementById("textEdit").innerHTML = result;

      let elements = document.getElementsByClassName("discard-replace-button");

      for (let i = 0; i < elements.length; i++) {
        console.log(elements[i].id);
        elements[i].addEventListener(
          "click",
          function () {
            this.replaceText(parseInt(elements[i].id));
          }.bind(this),
          false
        );
      }
    },
    checkPunctuation() {
      console.log("inside checkPunctuation");
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
        opacity: 0.1,
      });

      this.checkerQuery.text = document.getElementById("textEdit").innerText;

      this.axios({
        method: "post",
        url: this.true_hostname + "/8k/eight_k_spellchecker_process",
        data: this.checkerQuery,
      })
        .then((res) => {
          this.raw_result = res.data.result;
          this.rebuildTextForEdit();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        })
        .catch((error) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        });
    },
    replaceSynonyms(i, j) {
      this.words_with_scores[i].word = this.words_with_scores[i].synonyms[j];
      this.words_with_scores[i].synonyms = [];
      this.rebuildTextForEditSynonyms();
    },
    rebuildTextForEditSynonyms() {
      let result = "";
      this.textForEdit = "";

      for (let i = 0; i < this.words_with_scores.length; i++) {
        let w = this.words_with_scores[i].word;
        let s = this.words_with_scores[i].score;
        let syns = this.words_with_scores[i].synonyms;

        if (i - 1 >= 0) {
          let w_prev = this.words_with_scores[i - 1].word;
          if (
            ~!!w.match(/^[.,:!?\(\)\[\]]/) & ~!!w_prev.match(/^[.,:!?\(\)\[\]]/)
          )
            result += "<span> </span>";
        }

        let background_color = "";
        let mult_coeff = 2;
        if (s > 0) {
          s = Math.min(s * mult_coeff, 1);
          background_color = "rgb(0, 255, 0, " + s + ");";
        } else {
          s = Math.min(-s * mult_coeff, 1);
          background_color = "rgb(255, 0, 0, " + s + ");";
        }

        if (syns.length > 0) {
          let candidates = "";
          for (let j = 0; j < syns.length; j++) {
            candidates += `
                <a
                  id="${i}_${j}"
                  class="btn btn-outline-primary btn-sm mr-1 mt-1 p-1 replace-button"
                  >${syns[j]}</a
                > </br>`;
          }
          result += `<span style="background-color: ${background_color}">
                <div class="custom-tooltip" contenteditable="false">
                    ${w}
                    <span class="custom-tooltiptext custom-tooltip-top">
                    ${candidates}
                    </span>
                  </div>
              </span>`;
        } else {
          result += `<span style="background-color: ${background_color}">
                ${w}
              </span>`;
        }

        this.textForEdit += w;
      }
      document.getElementById("textEdit").innerHTML = result;

      let elements = document.getElementsByClassName("replace-button");
      for (let k = 0; k < elements.length; k++) {
        let s = elements[k].id.split("_");
        let i = parseInt(s[0]);
        let j = parseInt(s[1]);
        elements[k].addEventListener(
          "click",
          function () {
            this.replaceSynonyms(i, j);
          }.bind(this),
          false
        );
      }
    },
    checkTOV() {
      console.log("inside checkTOV");
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
        opacity: 0.1,
      });

      this.tovQuery.text = document.getElementById("textEdit").innerText;

      this.axios({
        method: "post",
        url: this.true_hostname + "/8k/eight_k_tov_process",
        //url: "http://10.21.21.101:8156" + "/tov_process",

        data: this.tovQuery,
      })
        .then((res) => {
          this.tov_score = (res.data.score * 100).toFixed(2);
          this.words_with_scores = res.data.words_with_scores;

          this.rebuildTextForEditSynonyms();

          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        });
    },
    replaceParaphrase(i) {
      console.log("replaceParaphrase", i);
      this.raw_result_paraphrases[
        i
      ].original_text = this.raw_result_paraphrases[i].candidate_text;
      this.rebuildTextForEditParaphrases();
    },
    rebuildTextForEditParaphrases() {
      let result = "";
      this.textForEdit = "";
      for (let i = 0; i < this.raw_result_paraphrases.length; i++) {
        if (
          this.raw_result_paraphrases[i].original_text !==
          this.raw_result_paraphrases[i].candidate_text
        )
          result +=
            `
                  <div class="custom-tooltip" contenteditable="false">` +
            this.raw_result_paraphrases[i].original_text +
            `<span class="custom-tooltiptext custom-tooltip-top">
                        <a
                          id="` +
            i +
            `"
                          class="btn btn-outline-primary btn-sm mr-2 mt-2 replace-button"
                          >Заменить на "` +
            this.raw_result_paraphrases[i].candidate_text +
            `"</a
                        >
                    </span>
                  </div>`;
        else result += this.raw_result_paraphrases[i].original_text;

        this.textForEdit += this.raw_result_paraphrases[i].original_text;
      }
      document.getElementById("textEdit").innerHTML = result;

      let elements = document.getElementsByClassName("replace-button");

      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener(
          "click",
          function () {
            this.replaceParaphrase(parseInt(elements[i].id));
          }.bind(this),
          false
        );
      }
    },
    checkParaphrases() {
      console.log("inside checkParaphrases");
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
        opacity: 0.1,
      });

      this.paraphraseseQuery.text = document.getElementById(
        "textEdit"
      ).innerText;

      this.axios({
        method: "post",
        url: this.true_hostname + "/8k/eight_k_paraphrases_process",
        //url: "http://10.21.21.101:8155" + "/check_paraphrases",

        data: this.paraphraseseQuery,
      })
        .then((res) => {
          this.raw_result_paraphrases = res.data.result;
          this.rebuildTextForEditParaphrases();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          console.log("checkParaphrases finished");
        })
        .catch((error) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          console.log("checkParaphrases finished");
        });
    },
    handleMessage(message, lastEventId) {
      console.log(message);
      document.getElementById("textEdit").innerHTML = message["data"];
    },
  },
  mounted() { },
};
</script>

<style>
[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-text);
}

.main-div {
  height: 500px;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  border: 1px solid #6c757d;
  border-radius: 6px;
  overflow-y: scroll;
}

.main-div-title {
  height: 50px;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  border: 1px solid #6c757d;
  border-radius: 6px;
  overflow-y: scroll;
}

.main-div-abstracts {
  height: 200px;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  border: 1px solid #6c757d;
  border-radius: 6px;
  overflow-y: auto;
}

.main-div-edit {
  height: 600px;
  box-sizing: border-box;
  padding: 100px 100px 100px 100px;
  border: 1px solid #6c757d;
  border-radius: 6px;
  overflow-x: hidden;
  position: relative;
}

.color-hover:hover {
  border-color: rgba(0, 201, 167, 0.9);
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted rgb(251, 255, 0);
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
}

.custom-tooltip .custom-tooltiptext {
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  /* Use half of the width (200/2 = 100), to center the tooltip */
}

.custom-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.custom-tooltip .custom-tooltiptext {
  opacity: 0;
  transition: opacity 0.5s;
}

.custom-tooltip:hover .custom-tooltiptext {
  opacity: 1;
}
</style>
